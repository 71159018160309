import { SVGProps } from 'react'

export const WarningIndicator = (props: SVGProps<SVGSVGElement>) => (
  <svg width={14} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" {...props}>
    <path
      d="M6.923 0c.384 0 .738.2.933.53l5.841 9.857c.198.333.198.742.006 1.075a1.086 1.086 0 0 1-.939.538H1.081c-.387 0-.747-.206-.939-.538a1.067 1.067 0 0 1 .006-1.075L5.99.53C6.184.2 6.539 0 6.923 0Zm0 3.429c-.36 0-.65.286-.65.642v3c0 .357.29.643.65.643.36 0 .649-.286.649-.643v-3a.644.644 0 0 0-.65-.642Zm.865 6a.853.853 0 0 0-.253-.607.87.87 0 0 0-1.224 0 .853.853 0 0 0 0 1.213.87.87 0 0 0 1.224 0 .853.853 0 0 0 .253-.606Z"
      fill="currentColor"
    />
  </svg>
)
