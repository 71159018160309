import useTranslation from 'next-translate/useTranslation'
import { differenceInCalendarDays } from 'date-fns'
import { faComment, faHeart } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  PropertyListingStatus,
  ResidentialListingTag,
  ResidentialPropertyForSaleListing,
} from '@unreserved-frontend-v2/api/generated/graphql/types'
import { featureEnabled } from '@unreserved-frontend-v2/utils/feature-flags'
import { getSimpleDate, massageDate } from '@unreserved-frontend-v2/utils/date-formatters'
import { Badge, BadgeProps } from '@unreserved-frontend-v2/ui/badge/badge'
import { getDateSoldForUI } from '../../utils/get-date-sold-for-ui'
import { PROPERTY_LISTING_STATUS_LABELS } from '../../types/property-listing-status'
import { DaysOnMarketBadge } from './days-on-market-badge'
import { twMerge } from 'tailwind-merge'

export interface ListingPreviewerBadgesProps {
  /** listing data to build badges off */
  listing?: ResidentialPropertyForSaleListing | null
  /** A list of badges to show if customized */
  enabledBadges?: PreviewerBadges[]
  /** Class style overrides */
  className?: string
  /** Individual badge overrides */
  badgeOverrides?: Partial<{ [key in PreviewerBadges]: BadgeProps }>
}

const DAYS_ON_MARKET_CONSIDERED_NEW = 30

export enum PreviewerBadges {
  NEW = 'new',
  SOLD_DATE = 'soldDate',
  INQUIRED = 'inquired',
  LIKED = 'liked',
  TAGS = 'tags',
  DAYS_ON_MARKET = 'daysOnMarket',
  DISTANCE = 'distance',
}

export const DEFAULT_PREVIEWER_BADGES = [
  PreviewerBadges.NEW,
  PreviewerBadges.SOLD_DATE,
  PreviewerBadges.INQUIRED,
  PreviewerBadges.LIKED,
  PreviewerBadges.TAGS,
  PreviewerBadges.DAYS_ON_MARKET,
]

/**
 * Collection of badges to render based on listing data. Currently renders on top of
 * listing previewer component
 */
export const ListingPreviewerBadges = ({
  listing,
  enabledBadges = DEFAULT_PREVIEWER_BADGES,
  className,
  badgeOverrides,
}: ListingPreviewerBadgesProps) => {
  const { t } = useTranslation()

  const soldDate = getDateSoldForUI(listing, 'MMM d, yyyy')
  const isNew = listing?.dateListed
    ? differenceInCalendarDays(getSimpleDate(), getSimpleDate(massageDate(listing?.dateListed))) <=
      DAYS_ON_MARKET_CONSIDERED_NEW
    : false
  const distance = `${
    listing?.distance ? t('listings:distance-away-only', { distance: listing?.distance.formattedDistance }) : ''
  }`
  const tags = listing?.tags as ResidentialListingTag[] | undefined

  return listing ? (
    <div data-testid="badges" className={twMerge('-ml-2 -mt-2', className)}>
      {enabledBadges.includes(PreviewerBadges.NEW) && isNew && listing?.listingStatus !== PropertyListingStatus.Sold ? (
        <Badge
          data-testid="newBadge"
          colorVariant="primary"
          {...badgeOverrides?.[PreviewerBadges.NEW]}
          className={twMerge('ml-2 mt-2', badgeOverrides?.[PreviewerBadges.NEW]?.className)}
        >
          {t('listings:new')}
        </Badge>
      ) : null}
      {enabledBadges.includes(PreviewerBadges.SOLD_DATE) && listing.listingStatus === PropertyListingStatus.Sold ? (
        <Badge
          data-testid="dateSoldBadge"
          colorVariant="primary"
          {...badgeOverrides?.[PreviewerBadges.SOLD_DATE]}
          className={twMerge('ml-2 mt-2', badgeOverrides?.[PreviewerBadges.SOLD_DATE]?.className)}
        >
          {t(PROPERTY_LISTING_STATUS_LABELS[listing.listingStatus])} {soldDate ? `- ${soldDate}` : null}
        </Badge>
      ) : null}
      {enabledBadges.includes(PreviewerBadges.DISTANCE) && distance ? (
        <Badge
          data-testid="distanceBadge"
          colorVariant="neutral"
          {...badgeOverrides?.[PreviewerBadges.DISTANCE]}
          className={twMerge('ml-2 mt-2', badgeOverrides?.[PreviewerBadges.DISTANCE]?.className)}
        >
          {distance}
        </Badge>
      ) : null}
      {enabledBadges.includes(PreviewerBadges.INQUIRED) && listing?.inquiries?.length ? (
        <Badge
          data-testid="inquiredBadge"
          colorVariant="neutral"
          {...badgeOverrides?.[PreviewerBadges.INQUIRED]}
          className={twMerge('ml-2 mt-2', badgeOverrides?.[PreviewerBadges.INQUIRED]?.className)}
        >
          <FontAwesomeIcon icon={faComment} className="pr-1" /> {t('listings:inquired')}
        </Badge>
      ) : null}
      {enabledBadges.includes(PreviewerBadges.LIKED) && listing.liked ? (
        <Badge
          data-testid="likedBadge"
          colorVariant="neutral"
          {...badgeOverrides?.[PreviewerBadges.LIKED]}
          className={twMerge('ml-2 mt-2', badgeOverrides?.[PreviewerBadges.LIKED]?.className)}
        >
          <FontAwesomeIcon icon={faHeart} className="pr-1" /> {t('listings:liked')}
        </Badge>
      ) : null}
      {featureEnabled(process.env.NEXT_PUBLIC_TAGS) && enabledBadges.includes(PreviewerBadges.TAGS)
        ? tags?.map(({ name }) => {
            return (
              <Badge
                data-testid={`tag-${name}`}
                key={name}
                {...badgeOverrides?.[PreviewerBadges.TAGS]}
                className={twMerge('ml-2 mt-2 flex-shrink-0', badgeOverrides?.[PreviewerBadges.TAGS]?.className)}
              >
                {name}
              </Badge>
            )
          })
        : null}
      {enabledBadges.includes(PreviewerBadges.DAYS_ON_MARKET) ? (
        <DaysOnMarketBadge
          listing={listing}
          {...badgeOverrides?.[PreviewerBadges.DAYS_ON_MARKET]}
          className={twMerge('float-right mt-2 py-[3px]', badgeOverrides?.[PreviewerBadges.DAYS_ON_MARKET]?.className)}
        />
      ) : null}
    </div>
  ) : null
}
