import useTranslation from 'next-translate/useTranslation'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { WarningIndicator } from '@unreserved-frontend-v2/ui/icons/warning-indicator'
import { AugmentedSquareFootageInfoModal } from './augmented-square-footage-info-modal'
import { SquareFootage } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { DEFAULT_LOCALE } from '@unreserved-frontend-v2/utils/locale'

type AugmentedSquareFootageVariant =
  | 'desktop-listings-header'
  | 'mobile-listings-header'
  | 'desktop-details-table'
  | 'mobile-details-table'
  | 'desktop-details-list'
  | 'mobile-details-list'
  | 'gallery'

export interface AugmentedSquareFootageInfo {
  className?: string
  squareFootage: SquareFootage
  variant?: AugmentedSquareFootageVariant
}

const VariantStyles: {
  [key in AugmentedSquareFootageVariant]: {
    containerApprox?: string
    containerDerived?: string
    icon?: string
  }
} = {
  'desktop-listings-header': { containerApprox: 'justify-center' },
  'mobile-listings-header': {
    containerApprox: 'ml-0',
    icon: 'pb-0.5',
  },
  'desktop-details-table': { icon: 'pl-3 h-[17px]' },
  'mobile-details-table': { icon: 'pl-1 h-3.5 mb-1' },
  'desktop-details-list': { containerDerived: 'items-baseline', icon: 'pl-2' },
  'mobile-details-list': { containerDerived: 'overflow-hidden', icon: 'shrink-0 mb-0.5' },
  gallery: { containerApprox: 'justify-center' },
}

export function AugmentedSquareFootageInfo({
  squareFootage,
  className,
  variant = 'desktop-listings-header',
}: AugmentedSquareFootageInfo) {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const { t } = useTranslation()

  const disableNestedPropagation = (e?: React.MouseEvent) => {
    e?.preventDefault()
  }

  const onModalToggle = (isOpen: boolean) => (e?: React.MouseEvent) => {
    disableNestedPropagation(e)
    setIsInfoModalOpen(isOpen)
  }

  return (
    <span
      className={twMerge(
        'flex items-center',
        squareFootage?.approximateSquareFootage
          ? VariantStyles[variant].containerApprox
          : VariantStyles[variant].containerDerived,
        className
      )}
    >
      <span className="truncate pr-2 only:pr-0">
        {['desktop-details-table', 'desktop-details-list', 'mobile-details-table', 'mobile-details-list'].includes(
          variant
        )
          ? t('listings:details-pages.sq-ft', {
              count: (squareFootage?.approximateSquareFootage || squareFootage?.derivedSquareFootage)?.toLocaleString(
                DEFAULT_LOCALE
              ),
            })
          : t('listings:sq-ft')}
      </span>
      {squareFootage?.derivedSquareFootage && squareFootage?.derivedSquareFootageSource ? (
        <>
          <span
            onClick={onModalToggle(true)}
            className={twMerge(
              'text-shades-200 h-3 cursor-pointer opacity-100 transition duration-200 ease-in-out hover:opacity-50',
              VariantStyles[variant].icon
            )}
          >
            <WarningIndicator className="h-full w-auto" />
          </span>
          {isInfoModalOpen ? (
            <AugmentedSquareFootageInfoModal
              isOpen={true}
              onClose={onModalToggle(false)}
              onModalClick={disableNestedPropagation}
            />
          ) : null}
        </>
      ) : null}
    </span>
  )
}
