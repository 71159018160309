import { ResidentialPropertyForSaleListing } from '@unreserved-frontend-v2/api/generated/graphql/types'

export const LISTING = {
  // adding data here will override any listing data for a restricted listing
  listingPrice: {
    amount: '$XXX,XXX',
    amountSymbol: '$',
  },
  salePrice: {
    amount: null,
  },
  propertyDetails: {
    virtualTourUrl: null,
  },
} as Partial<ResidentialPropertyForSaleListing>
