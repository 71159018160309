import useTranslation from 'next-translate/useTranslation'
import { useMemo } from 'react'
import { faBath, faBed, faRulerTriangle } from '@fortawesome/pro-solid-svg-icons'

import {
  PropertyListingStatus,
  ResidentialPropertyForSaleListing,
  SquareFootage,
} from '@unreserved-frontend-v2/api/generated/graphql/types'
import { getAddressCaption } from '@unreserved-frontend-v2/geolocation/utils/utils'
import { InfoItem } from '@unreserved-frontend-v2/ui/info-set/info-set'
import { currency } from '@unreserved-frontend-v2/utils/currency'
import { AugmentedSquareFootageInfo } from '../../components/augmented-square-footage-info/augmented-square-footage-info'

export interface CompactListingInfo {
  title: string
  caption: string
  infoItems: InfoItem[]
  isListingAvailable?: boolean
}

export const useCompactListingInfo = (
  listing: ResidentialPropertyForSaleListing,
  isRestricted?: true,
  isListingAvailable = true,
  showNeighbourhoods = false
): CompactListingInfo => {
  const { t } = useTranslation()

  return useMemo(() => {
    const items: InfoItem[] = []

    if (isListingAvailable) {
      const { beds, baths, squareFootage } = listing?.propertyDetails || { propertyDetails: {} }

      if ([squareFootage?.approximateSquareFootage, squareFootage?.derivedSquareFootage].some(Boolean)) {
        items.push({
          value: squareFootage?.approximateSquareFootage || squareFootage?.derivedSquareFootage,
          subValue: (
            <AugmentedSquareFootageInfo
              squareFootage={squareFootage as SquareFootage}
              variant="mobile-listings-header"
            />
          ),
          subValueClass: '[&:not(:only-child):not([hidden])]:ml-1',
          icon: faRulerTriangle,
          iconContainerClassName: `[&>.icon-placeholder]:w-5 ${isRestricted ? ' blur-sm select-none' : ''}`,
          id: 'sqft',
        })
      }

      if (beds != null) {
        items.push({ value: beds, icon: faBed, iconContainerClassName: '[&>.icon-placeholder]:w-[23px]', id: 'bed' })
      }

      if (baths !== null) {
        items.push({ value: baths, icon: faBath, iconContainerClassName: '[&>.icon-placeholder]:w-5', id: 'bath' })
      }

      items.push(
        listing?.listingStatus === PropertyListingStatus.Sold &&
          !isRestricted &&
          listing?.salePrice?.amount !== listing?.listingPrice?.amount
          ? {
              subValue: currency(listing?.listingPrice?.amount),
              subValueClass: 'line-through font-normal pr-2',
              value: currency(listing?.salePrice?.amount),
              valueClass: 'text-primary',
            }
          : {
              key: `listings:${listing?.listingStatus === PropertyListingStatus.Sold ? 'sale-price' : 'listing-price'}`,
              value: currency(listing?.listingPrice?.amount),
              valueClass: listing?.listingStatus === PropertyListingStatus.Sold ? 'text-primary' : '',
            }
      )
    }

    return {
      title: listing?.property.address.address1 || '',
      caption: `${getAddressCaption(listing?.property.address, showNeighbourhoods)}${
        listing?.distance ? t('listings:distance-away', { distance: listing.distance.formattedDistance }) : ''
      }`,
      infoItems: items,
    }
  }, [
    isListingAvailable,
    isRestricted,
    listing.distance,
    listing?.listingPrice?.amount,
    listing?.listingStatus,
    listing?.property.address,
    listing?.propertyDetails,
    listing?.salePrice?.amount,
    showNeighbourhoods,
    t,
  ])
}
