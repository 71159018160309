import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'

import RegisterOrSignInModal from './register-or-sign-in-modal'
import { tns } from '../../../listings/translationNamespace'
import { Button } from '@unreserved-frontend-v2/ui/button/button'

export const RegisterOrSignInButton = () => {
  const { t } = useTranslation(tns)
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <Button
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        {t('users:register-or-sign-in')}
      </Button>
      <RegisterOrSignInModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  )
}
