import Script, { ScriptProps } from 'next/script'
import { useEffect } from 'react'

export const useDynamicInlineScript = (content: string, id: string, props?: ScriptProps, isDisabled = false) => {
  // Update script content whenever it changes
  useEffect(() => {
    if (isDisabled) {
      return
    }
    const elementById = document.getElementById(id)
    if (elementById) {
      elementById.innerHTML = content
    }
  }, [id, content, isDisabled])

  // Destroy the script when unmounted
  useEffect(() => {
    return () => {
      const elementById = document.getElementById(id)
      if (!isDisabled && elementById) {
        elementById.innerHTML = ''
      }
    }
  }, [id, isDisabled])

  return content ? <Script {...props} id={id} dangerouslySetInnerHTML={{ __html: content }} /> : null
}
