import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Link from 'next/link'
import { ScriptProps } from 'next/script'
import useTranslation from 'next-translate/useTranslation'
import { ReactNode, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import { useDynamicInlineScript } from '@unreserved-frontend-v2/utils/hooks/use-dynamic-inline-script'

import { InfoItem, InfoSetMetadata } from './info-set'
import { RestrictableContent } from '../utils/types'

const FontAwesomeIcon: React.ComponentType<FontAwesomeIconProps> = dynamic(
  () => import('@fortawesome/react-fontawesome').then((pkg) => pkg.FontAwesomeIcon),
  {
    ssr: false,
    loading: () => <div className="icon-placeholder" />,
  }
)

interface InfoSetProps extends RestrictableContent {
  /** Info items to render, the last item will render on a separate line */
  infoItems?: InfoItem[]
  title: ReactNode | string
  meta?: InfoSetMetadata
  titleClassName?: string
  caption?: ReactNode | string
  headerComponent?: React.ReactNode
  /** if a href is provided, info set will be wrapped in a Link */
  href?: string
  /** the title attribute of the link */
  hrefTitle?: string
  /** use to wrap or truncate address caption */
  isCaptionTruncated?: boolean
  headerTagType?: keyof JSX.IntrinsicElements
}

export const InfoSetMobileTitleClasses = 'min-w-0 flex-grow truncate pr-1 text-lg font-bold'

export function InfoSetMobile({
  infoItems = [],
  title,
  meta,
  titleClassName,
  caption,
  headerComponent,
  isRestricted,
  href,
  hrefTitle,
  isCaptionTruncated = true,
  headerTagType: HeaderTag = 'h2',
}: InfoSetProps) {
  const { t } = useTranslation()
  const rightItem = useMemo(() => infoItems[infoItems.length - 1], [infoItems])
  const leftItems = useMemo(
    () =>
      infoItems.reduce((memo, item, index) => {
        if (index === infoItems.length - 1 || item.value === 0) {
          return memo
        }

        return [...memo, item]
      }, [] as InfoItem[]),
    [infoItems]
  )

  // Schema.org setup
  const { schemaContent, scriptProps } = useMemo(() => {
    return {
      schemaContent:
        headerComponent && meta?.schemaOrgProductDetail ? JSON.stringify(meta?.schemaOrgProductDetail) : '',
      scriptProps: { type: 'application/ld+json', strategy: 'beforeInteractive' } as ScriptProps,
    }
  }, [headerComponent, meta?.schemaOrgProductDetail])
  const schemaOrgScript = useDynamicInlineScript(
    schemaContent,
    'schema-org-product-detail',
    scriptProps,
    !headerComponent
  )

  const { schemaBreadcrumbContent, schemaBreadCrumbScriptProps } = useMemo(() => {
    return {
      schemaBreadcrumbContent: meta?.schemaBreadcrumbList || '',
      schemaBreadCrumbScriptProps: { type: 'application/ld+json', strategy: 'beforeInteractive' } as ScriptProps,
    }
  }, [meta?.schemaBreadcrumbList])

  const schemaOrgBreadcrumbScript = useDynamicInlineScript(
    schemaBreadcrumbContent,
    'schema-org-breadcrumb-list',
    schemaBreadCrumbScriptProps,
    !headerComponent
  )

  const contents = (
    <>
      <div className="flex" data-nosnippet={isRestricted}>
        {headerComponent ? (
          <>
            <Head>
              <title>{meta?.metaTitle}</title>
              {meta?.metaDescription ? <meta name="description" content={meta.metaDescription} /> : null}
              {meta?.canonicalLink ? <link rel="canonical" href={meta?.canonicalLink} /> : null}
              {meta?.openGraphMetadata}
            </Head>
            {schemaOrgScript}
            {schemaOrgBreadcrumbScript}
            {headerComponent}
          </>
        ) : (
          <HeaderTag className={twMerge(InfoSetMobileTitleClasses, titleClassName, isRestricted ? 'blur-sm' : '')}>
            {title}
          </HeaderTag>
        )}
        <div className={twMerge('flex-grow pl-2 text-right text-lg font-bold', titleClassName)}>
          {rightItem && rightItem.value && !rightItem.subValue ? (
            <span className={rightItem.valueClass}>{rightItem.value}</span>
          ) : null}
          {rightItem && rightItem.subValue ? (
            <>
              <span className={rightItem.subValueClass}>{rightItem.subValue}</span>
              <span className={rightItem.valueClass}>{rightItem.value}</span>
            </>
          ) : null}
        </div>
      </div>
      <div className={twMerge(isCaptionTruncated ? 'truncate' : '', isRestricted ? 'blur-sm' : '')}>{caption}</div>
    </>
  )

  return (
    <>
      {href ? (
        <Link title={hrefTitle} href={href}>
          {contents}
        </Link>
      ) : (
        contents
      )}
      <ul className="flex space-x-4 pt-1">
        {leftItems.map((item) => (
          <li
            key={item.id}
            className={twMerge('flex items-center space-x-2 truncate leading-[20px]', item.iconContainerClassName)}
          >
            {!item.icon ? null : <FontAwesomeIcon icon={item.icon} className="pr-2 text-sm text-shades-600" />}
            {item.value} {item.key ? t(item.key) : null}
            {item.subValue ? <span className={item.subValueClass}>{item.subValue}</span> : null}
          </li>
        ))}
      </ul>
    </>
  )
}

export default InfoSetMobile
