/**
 */

import { DEFAULT_LOCALE } from './locale'

/**
 * Formats the passed-in number to an equivalent cash value.
 *
 * @param n The number to format.
 * @param roundKs True to round up numbers that are under 1 Million.
 * @returns The number formatted as the equivalent cash amount.
 *
 * taken from stack overflow:
 * https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
 *
 */
export const formatCash = (n: number, roundKs = false): string => {
  if (n < 1e3) {
    return n.toFixed(2).toString()
  }

  if (n >= 1e3 && n < 1e6) {
    if (roundKs) {
      // Make sure to cover the case where the rounded up number is >= 1000000 and have to return 1M.
      const rounded = Math.round(Number.parseFloat((n / 1e3).toFixed(1)))
      if (rounded >= 1e3) {
        return `${+(rounded / 1e3).toFixed(1)}M`
      }

      return `${+rounded}K`
    }

    return `${+(n / 1e3).toFixed(1)}K`
  }

  if (n >= 1e6 && n < 1e9) {
    return +(n / 1e6).toFixed(1) + 'M'
  }
  if (n >= 1e9 && n < 1e12) {
    return +(n / 1e9).toFixed(1) + 'B'
  }
  if (n >= 1e12) {
    return +(n / 1e12).toFixed(1) + 'T'
  }

  return ''
}

/**
 * Formats an amount to a simple display currency in CAD
 */
export const currency = (amount?: number, shorten?: boolean, roundKs = false): string => {
  if (amount == null) {
    return ''
  }

  if (!shorten) {
    return amount.toLocaleString(DEFAULT_LOCALE, {
      style: 'currency',
      currency: 'CAD',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
  }

  return `$${formatCash(amount as number, roundKs)}`
}
