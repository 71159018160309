import { twMerge } from 'tailwind-merge'

import ImageWithShimmer, { ImageWithShimmerProps } from './image-with-shimmer'
import { CropType, getResizedImage, getSrcAndSrcSet } from './utils'
import { RestrictableContent } from '../utils/types'

interface ImageWithShimmerOptimizedProps extends RestrictableContent, Omit<ImageWithShimmerProps, 'srcset'> {
  width: number
  height: number
  crop?: CropType
  isEagerLoaded?: boolean
}

// This factor is used to reduce the size of the image when it is rendered blurred
const RESTRICTED_RESIZE_FACTOR = 5

export function ImageWithShimmerOptimized({
  src,
  width,
  height,
  crop = CropType.FIT,
  className = '',
  id,
  isRestricted,
  style = {},
  imgClassName,
  isEagerLoaded,
  ...restOfProps
}: ImageWithShimmerOptimizedProps) {
  const imgProps = !isRestricted
    ? getSrcAndSrcSet(src, width, height, crop)
    : {
        src: getResizedImage(
          src,
          Math.round(width / RESTRICTED_RESIZE_FACTOR),
          Math.round(height / RESTRICTED_RESIZE_FACTOR),
          crop
        ),
        srcset: getResizedImage(
          src,
          Math.round(width / RESTRICTED_RESIZE_FACTOR),
          Math.round(height / RESTRICTED_RESIZE_FACTOR),
          crop
        ),
      }

  return (
    <ImageWithShimmer
      id={id}
      className={twMerge(className, isRestricted ? 'overflow-hidden' : '')}
      imgClassName={twMerge(imgClassName, isRestricted ? 'blur-lg' : '')}
      src={imgProps.src}
      srcset={imgProps.srcset}
      data-nosnippet={isRestricted}
      style={style}
      loading={isEagerLoaded ? 'eager' : 'lazy'}
      {...restOfProps}
    />
  )
}

export default ImageWithShimmer
