export enum CropType {
  FIT = 'f',
  STRETCH = 's',
}

export const getResizedImage = (url: string, w: number, h: number, crop: CropType): string => {
  const urlSplit = url.split('.')
  const extension = urlSplit.pop()

  return `${urlSplit.join('.')}_${w}_${h}_${crop}.${extension}`
}

export const getSrcAndSrcSet = (url: string, w: number, h: number, crop: CropType) => {
  return {
    src: getResizedImage(url, w, h, crop),
    srcset: `${getResizedImage(url, w, h, crop)} 1x, ${getResizedImage(url, w * 2, h * 2, crop)} 2x, ${getResizedImage(
      url,
      w * 3,
      h * 3,
      crop
    )} 3x`,
  }
}
