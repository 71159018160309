import { tns } from '../translationNamespace'
import { PropertyListingStatus } from '@unreserved-frontend-v2/api/generated/graphql/types'

export const PROPERTY_LISTING_STATUS_ALL = 'ALL'

export const PROPERTY_LISTING_STATUS = [
  { label: `${tns}:property-listing-status.active`, value: PropertyListingStatus.Active },
  { label: `${tns}:property-listing-status.cancelled`, value: PropertyListingStatus.Cancelled },
  { label: `${tns}:property-listing-status.coming-soon`, value: PropertyListingStatus.ComingSoon },
  { label: `${tns}:property-listing-status.conditionally-sold`, value: PropertyListingStatus.ConditionallySold },
  { label: `${tns}:property-listing-status.draft`, value: PropertyListingStatus.Draft },
  { label: `${tns}:property-listing-status.expired`, value: PropertyListingStatus.Expired },
  { label: `${tns}:property-listing-status.inactive`, value: PropertyListingStatus.Inactive },
  { label: `${tns}:property-listing-status.no-sale`, value: PropertyListingStatus.NoSale },
  { label: `${tns}:property-listing-status.sale-pending`, value: PropertyListingStatus.SalePending },
  { label: `${tns}:property-listing-status.sold`, value: PropertyListingStatus.Sold },
  { label: `${tns}:property-listing-status.suspended`, value: PropertyListingStatus.Suspended },
  { label: `${tns}:property-listing-status.duplicate`, value: PropertyListingStatus.Duplicate },
  { label: `${tns}:property-listing-status.active-under-contract`, value: PropertyListingStatus.ActiveUnderContract },
  { label: `${tns}:property-listing-status.closed`, value: PropertyListingStatus.Closed },
  { label: `${tns}:property-listing-status.delete`, value: PropertyListingStatus.Delete },
  { label: `${tns}:property-listing-status.hold`, value: PropertyListingStatus.Hold },
  { label: `${tns}:property-listing-status.incomplete`, value: PropertyListingStatus.Incomplete },
  { label: `${tns}:property-listing-status.pending`, value: PropertyListingStatus.Pending },
  { label: `${tns}:property-listing-status.withdrawn`, value: PropertyListingStatus.Withdrawn },
]

export const PROPERTY_LISTING_STATUS_LABELS: Record<PropertyListingStatus, string> = PROPERTY_LISTING_STATUS.reduce(
  (memo, item) => ({ ...memo, [item.value]: item.label }),
  {} as Record<PropertyListingStatus, string>
)
