import useTranslation from 'next-translate/useTranslation'

import { Button } from '@unreserved-frontend-v2/ui/button/button'
import { Modal } from '@unreserved-frontend-v2/ui/modal/modal'
import { WarningIndicator } from '@unreserved-frontend-v2/ui/icons/warning-indicator'

export type SquareFootageInfoModalProps = {
  isOpen: boolean
  onClose: (e: React.MouseEvent) => void
  onModalClick?: (e: React.MouseEvent) => void
}

export const AugmentedSquareFootageInfoModal = ({ isOpen, onClose, onModalClick }: SquareFootageInfoModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentSx={{
        padding: '0px',
        textAlign: 'start',
      }}
      iconSx={{ right: '7px', top: '6px' }}
      maxWidth="xxs"
    >
      <div className="flex flex-col justify-center text-center" onClick={onModalClick}>
        <WarningIndicator className="mt-8 mb-6 h-[71px] w-auto text-orange-600" />
        <p className="text-shades-600 mb-7 px-8 leading-7">{t('listings:augmented-square-footage.caption')}</p>
        <div className="border-shades-200 flex justify-center border-t">
          <Button variant="unstyled" className="text-primary m-0 p-0" onClick={onClose}>
            {t('listings:augmented-square-footage.close')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
