import useTranslation from 'next-translate/useTranslation'

import { tns } from '../../../listings/translationNamespace'
import { Button } from '@unreserved-frontend-v2/ui/button/button'
import { useRouter } from 'next/router'

export const RegisterOrSignInButtonRedirect = () => {
  const { t } = useTranslation(tns)
  const { asPath } = useRouter()

  return (
    <Button rel="nofollow" href={`/register-or-sign-in${asPath ? `?redirectTo=${encodeURIComponent(asPath)}` : ''}`}>
      {t('users:register-or-sign-in')}
    </Button>
  )
}
