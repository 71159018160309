import useTranslation from 'next-translate/useTranslation'
import {
  PropertyListingStatus,
  ResidentialPropertyForSaleListing,
} from '@unreserved-frontend-v2/api/generated/graphql/types'
import { Badge, BadgeProps } from '@unreserved-frontend-v2/ui/badge/badge'
import { getDaysOnMarket } from '../../utils/formatters/days-on-market'

export interface DaysOnMarketBadgeProps extends BadgeProps {
  /** listing data to build badges off */
  listing?: ResidentialPropertyForSaleListing | null
  className?: string
}
export const DaysOnMarketBadge = ({ listing, ...badgeProps }: DaysOnMarketBadgeProps) => {
  const { t } = useTranslation()
  const daysOnMarket = getDaysOnMarket(listing)

  return daysOnMarket !== undefined ? (
    <Badge data-testid="daysOnMarketBadge" {...badgeProps}>
      {t(
        listing?.listingStatus === PropertyListingStatus.Sold
          ? 'listings:days-on-market-sold'
          : 'listings:days-on-market',
        { count: daysOnMarket }
      )}
    </Badge>
  ) : null
}
