import useTranslation from 'next-translate/useTranslation'
import { twMerge } from 'tailwind-merge'
import { tns } from '../../../listings/translationNamespace'
import { RegisterOrSignInButton } from './register-or-sign-in-button'
import { RegisterOrSignInButtonRedirect } from './register-or-sign-in-button-redirect'

export interface RegisterOrSignInPromptProps {
  /** Any extra classnames we want to pass to the component. */
  className?: string
  isSold: boolean
  useRedirectButton?: boolean
}

export function RegisterOrSignInPrompt({ className, isSold, useRedirectButton }: RegisterOrSignInPromptProps) {
  const { t } = useTranslation(tns)
  const registerCopyKey = `register-${isSold ? 'sold' : 'for-sale'}`

  return (
    <div
      className={twMerge(
        'absolute top-1/2 left-1/2 z-[1] flex -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-2 text-center text-white',
        className
      )}
    >
      <div className="text-lg font-bold">{t(`listings:restricted-listing-${registerCopyKey}.title`)}</div>
      <div className="mb-3 text-base leading-7">{t(`listings:restricted-listing-${registerCopyKey}.caption`)}</div>

      {useRedirectButton ? <RegisterOrSignInButtonRedirect /> : <RegisterOrSignInButton />}
    </div>
  )
}

export default RegisterOrSignInPrompt
