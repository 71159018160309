import { FeatureCollection } from 'geojson'

import { Address, GeocodedAddress, ValidateAddressInput } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { FilterChangeEvent } from '@unreserved-frontend-v2/ui/schema-table/models'

import { getBoundsForPointAndDistance, getCircleForPointAndRadius } from '../components/map/utils-lite'
import { GeoFilterChangeEvent } from '../types'

/**
 * Returns a caption corresponding to the street information, including the unit number.
 * @param address The Address to extract information from.
 * @returns A string representing the street information (e.g. - 508 - 11 Brunel Ct).
 */
export function getStreetCaption(address?: Partial<Address>) {
  if (!address) {
    return ''
  }

  if (!address.addressNormalized) {
    return address.address1 as string
  }

  const { unitNumber, streetNumber, streetNameShort } = address

  return `${unitNumber ? unitNumber + ' - ' : ''}${streetNumber} ${streetNameShort}`
}

/**
 *
 * @param address The Address to extract information from.
 * @returns A string representing the full address in a simple form (e.g. - 508 - 11 Brunel Ct, Toronto, ON).
 */
export function getSimpleAddress(address?: Partial<Address>, withPostalCode?: boolean) {
  if (!address) {
    return ''
  }

  return (
    [
      getStreetCaption(address),
      address.cityNameShort,
      address.regionNameAbbreviated,
      withPostalCode ? address.zipCode : false,
    ]
      .filter(Boolean)
      .join(', ') || ''
  )
}

/**
 * @description Returns a full address caption for the passed-in address data.
 * @param address - The address data to extract information from.
 */
export function getAddressCaption(address: Partial<Address> | undefined | null, showNeighbourhoods = false): string {
  if (!address) {
    return ''
  }

  return (
    [
      showNeighbourhoods
        ? address.macroNeighbourhoodNameShort || address.subNeighbourhoodNameShort || address.neighbourhoodNameShort
        : null,
      address.cityNameShort,
      address.regionNameAbbreviated,
      address.zipCode,
    ]
      .filter(Boolean)
      .join(', ') || ' '
  )
}

/**
 * Converts an Address to a ValidateAddressInput
 *
 * @param address Address to convert
 * @returns the converted address as a ValidateAddressInput
 */
export const mapAddressToValidateAddressInput = (address: Address): ValidateAddressInput => {
  return {
    city: address?.cityNameShort || '',
    country: address?.countryNameShort || '',
    latitude: address?.latitude || 0,
    longitude: address?.longitude || 0,
    region: address?.regionNameAbbreviated || '',
    streetName: address?.streetNameShort || '',
    streetNumber: address?.streetNumber || '',
    unitNumber: address?.unitNumber || address?.address2 || '',
    zipCode: address?.zipCode || '',
  }
}

export function geocodedAddressToString(address: GeocodedAddress) {
  const { streetNumber, streetName, city, region } = address

  return `${streetNumber} ${streetName}, ${city}, ${region}`
}

/**
 * Converts a GeocodedAddress to an Address
 *
 * @param address address to convert
 * @returns the converted address as an Address
 */
export const mapGeocodedAddressToAddress = (address: GeocodedAddress): Partial<Address> => ({
  __typename: 'Address',
  address1: address.address1 || '',
  unitNumber: address.address2 || '',
  cityNameShort: address.city || '',
  countryNameShort: address.country || '',
  latitude: address.latitude || 0,
  longitude: address.longitude || 0,
  metroAreaNameShort: address.metroArea || '',
  regionNameAbbreviated: address.region || '',
  streetNameShort: address.streetName || '',
  streetNumber: address.streetNumber || '',
  zipCode: address.zipCode || '',
})

/**
 * Converts a GeocodedAddress to a ValidateAddressInput
 *
 * @param address address to convert
 * @returns the converted address as a ValidateAddressInput
 */
export const mapGeocodedAddressToValidateAddressInput = (address: GeocodedAddress): ValidateAddressInput => {
  return {
    city: address?.city || '',
    country: address?.country || '',
    latitude: address?.latitude || 0,
    longitude: address?.longitude || 0,
    region: address?.region || '',
    streetName: address?.streetName || '',
    streetNumber: address?.streetNumber || '',
    unitNumber: '',
    zipCode: address?.zipCode || '',
  }
}

/**
 * Returns a map bounds based on a center point and distance as a radius.
 * @param e The geo search filter event to use.
 * @returns A map bounds that encompases the point and a radius of the event's distance.
 */
export function getBoundsForGeoFilterEvent(e: unknown) {
  const geoEvent = e as GeoFilterChangeEvent
  const lng = geoEvent.value['longitude']
  const lat = geoEvent.value['latitude']
  const bounds = getBoundsForPointAndDistance(lng, lat, geoEvent.value.distance.value)

  return bounds
}

export function getGeoFeatureForGeoFilterEvent(e: unknown) {
  const geoEvent = e as GeoFilterChangeEvent
  const lng = geoEvent.value['longitude']
  const lat = geoEvent.value['latitude']
  const feature = getCircleForPointAndRadius(lng, lat, geoEvent.value.distance.value)

  return feature
}

export function haveGeoSearchEventWithValue(events: FilterChangeEvent[]) {
  return events.find((e) => {
    return ['geo', 'placeSlugs'].includes(e.key) && e.value
  })
}

export const NO_FEATURES = { type: 'FeatureCollection', features: [] } as FeatureCollection
